<template>
    <div class="page-body clone-dashboard">
        <NoButtonModal :open="true">
            <div class="modal-content-header bottom-20">
                <span style="font-size: 20px; font-weight: bold; margin-left: 20px">{{
                    (categoryIndex !== null ? 'Edit' : 'Create') + ' Category'
                }}</span>
            </div>
            <ValidationObserver ref="form">
                <form style="box-shadow: none" class="p-0" @submit.prevent="submit">
                    <div class="d-flex flex-column align-items-center" style="gap: 10px">
                        <div class="w-80 mx-4">
                            <Input
                                label="Category Name"
                                name="Category Name"
                                id="categoryNameInput"
                                placeholder="Enter category name"
                                inputClass="input-add-new-dashboard mt-3"
                                labelClass="class-label"
                                :input_style="'text-transform:capitalize'"
                                v-model="categoryDetails.title"
                                required
                            />
                        </div>
                        <div class="my-4 d-flex justify-content-center" style="bottom: 10%">
                            <button type="submit" class="primary btn-save">Save</button>
                            <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">Cancel</button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { ValidationObserver } from 'vee-validate';

    export default {
        name: 'CategoryModal',
        components: { NoButtonModal, ValidationObserver },
        data() {
            return {
                categoryDetails: {
                    title: '',
                },
            };
        },
        props: {
            categoryList: {
                type: Array,
            },
            categoryInfo: {
                type: [Object, null],
            },
            categoryIndex: {
                type: [Number, null],
            },
        },
        methods: {
            async submit() {
                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    return;
                }
                if (!this.categoryDetails.title?.trim().length) {
                    this.categoryDetails.title = '';
                    return this.$toasted.error('Please enter category name');
                }
                if (this.categoryDetails.title.length > 50) {
                    return this.$toasted.error('Category name should not be more than 50 characters');
                }
                const isCategoryNamePresent = this.categoryList.find(
                    (category, index) =>
                        category.title.toLowerCase() === this.categoryDetails.title.toLowerCase() &&
                        index !== this.categoryIndex
                );
                if (isCategoryNamePresent) {
                    return this.$toasted.error('This Category name is already exist');
                }
                this.$emit('save', this.categoryDetails);
            },
        },
        created() {
            if (this.categoryInfo) {
                this.categoryDetails = { ...this.categoryInfo };
            }
        },
    };
</script>

<style scoped>
    form {
        min-height: fit-content !important;
    }

    .modal-content-header {
        background-color: #f1f7ff;
        padding: 10px;
        display: flex;
        align-items: center;
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .text-link:hover {
        background: #5155c3;
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .clone-dashboard .modal__content {
        padding: 0;
        border-radius: 10px;
        min-height: fit-content;
    }

    .clone-dashboard .class-label {
        color: #666c6d;
        font-size: 1rem;
        font-weight: 600;
    }
</style>
