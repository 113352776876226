<template>
    <div>
        <ValidationObserver ref="form">
            <form style="border-radius: 15px; padding: 2rem">
                <!-- Company information re-design -->

                <div class="content-company-info">
                    <div class="d-flex justify-content-between align-items-center">
                        <ejs-tooltip class="tooltipcontainer" content="Back" target="#target" cssClass="customtooltip">
                            <span id="target" role="button" class="material-icons-outlined custom-arrow-back-icon"
                                @click="back">
                                arrow_back_ios_new
                            </span>
                        </ejs-tooltip>
                        <!-- <button v-if="!isEditable" type="button" class="primary btn-edit-company-info" @click="onClickEdit()">
                            <span class="material-symbols-outlined icon-symbol-edit">edit_square</span>
                            <span class="mt-1" style="font-weight: 700;">Edit Profile</span>
                        </button>  -->
                    </div>
                    <div class="content-company-info-form mt-5">
                        <div v-if="!isNext">
                            <div class="d-flex justify-content-between bottom-30">
                                <!-- <div class="d-flex justify-content-end align-items-center section-photo-upload w-48">
                                    <div class="d-flex flex-column pe-5">
                                        <span class="text-hint-main">Upload Tips</span>
                                        <span class="text-sub-hint">Avoid color backgrounds.</span>
                                        <span class="text-sub-hint">Best resolution 320px (width) 110px (Height)</span>
                                    </div>
                                    <div class="block-logo-upload">
                                        <div class="image-container">
                                            <div class="overlay" v-if="isEditable">
                                                <div class="upload" @click="selectFile">
                                                    <span class="material-icons-outlined">file_upload</span> &nbsp;
                                                    Upload
                                                </div>
                                                <div>
                                                    <input
                                                        id="company_logo"
                                                        type="file"
                                                        name="upload"
                                                        ref="upload"
                                                        @change="uploadImage($event)"
                                                        hidden
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div v-if="image" style="height: 100%">
                                                <img :src="image" alt="" class="img-wrap" />
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <Input class="w-48" inputClass="company-form-field" type="text" name="company name"
                                    id="company_name" placeholder="Company Name" v-model="record_data.company_name"
                                    label="Company Name" :disabled="!isEditable" required />
                            </div>
                            <div class="d-flex justify-content-between bottom-30">
                                <Input class="w-48" inputClass="company-form-field" type="text" name="company email"
                                    id="company_email" rules="email" placeholder="Company Email"
                                    v-model="record_data.company_email" label="Company Email" :disabled="!isEditable"
                                    required />
                                <Input class="w-48" inputClass="company-form-field" type="text" name="phone" id="phone"
                                    rules="phone" placeholder="000-000-0000" v-model="record_data.phone"
                                    label="Phone Number" :disabled="!isEditable" required />
                            </div>
                            <div class="d-flex justify-content-between bottom-30">
                                <Input class="w-48" inputClass="company-form-field" type="text" name="company website"
                                    id="company_website" rules="ValidateUrl" placeholder="Company Website (URL)"
                                    v-model="record_data.company_website" label="Company Website (URL)"
                                    :disabled="!isEditable" required />
                                <TextArea class="w-48" inputClass="company-form-field" type="text" id="address"
                                    name="address" :rows="2" :cols="100" label="Address" v-model="record_data.address"
                                    :limit="4000" :disabled="!isEditable" required />
                            </div>
                            <div class="d-flex justify-content-between bottom-30">
                                <div class="flex colx2 space-between w-47">
                                    <Input type="text" name="city" id="city" placeholder="City"
                                        v-model="record_data.city" label="City" :disabled="!isEditable"
                                        class="field-container w-49" inputClass="field-city" required />
                                    <UsStates v-model="record_data.state" id="state" name="state" label="State"
                                        :disabled="!isEditable" placeholder="-------- Select --------"
                                        class="field-container w-49" />
                                </div>
                                <Input class="w-48" inputClass="company-form-field" type="text" name="zip" id="zip"
                                    placeholder="Zip Code" v-model="record_data.zip" label="Zip Code"
                                    :disabled="!isEditable" required />
                            </div>
                            <div v-if="isEditable" class="d-flex justify-content-between bottom-40">
                                <div class="w-48">
                                    <label>Sensitive Screen Timeout*</label>
                                    <Multiselect id="sensitiveScreenTimeout" track-by="id" label="value"
                                        class="position-relative" placeholder="Select timeout"
                                        v-model="sensitiveScreenTimeout" :options="sensitiveScreenTimeoutList"
                                        :searchable="true" :allow-empty="false" :show-labels="false"
                                        @input="record_data.sensitiveScreenTimeout = $event.id"
                                        :disabled="!isEditable" />
                                </div>
                                <div class="w-48">
                                    <label>Sensitive Screen Inactivity Timeout*</label>
                                    <Multiselect id="sensitiveAlertTimeout" track-by="id" label="value"
                                        class="position-relative" placeholder="Select timeout"
                                        v-model="sensitiveAlertTimeout" :options="sensitiveAlertTimeoutList"
                                        :searchable="true" :allow-empty="false" :show-labels="false"
                                        @input="record_data.sensitiveAlertTimeout = $event.id"
                                        :disabled="!isEditable" />
                                </div>
                            </div>
                            <div class="d-flex flex-column pt-2 justify-content-between">
                                <Input class="w-48" inputClass="company-form-field" type="number" required
                                    name="Buffer Duration" id="bufferDuration" placeholder="Enter Buffer Duration"
                                    v-model="record_data.bufferDuration" :disabled="!isEditable" label="Buffer Duration (in seconds)"
                                    @input="validateBufferDuration" />
                                <span v-if="bufferDurationErr"
                                    style="color: #A82A3D; font-weight: 600; font-size: 13px">{{ bufferDurationErr
                                }}</span>
                            </div>
                        </div>

                        <div v-else class="mx-4" style="display: flex; flex-direction: column; gap: 1rem">
                            <div>
                                <label for="kioskColor">
                                    <h3 style="font-weight: 700">MobexHealth Community Color Scheme*</h3>
                                </label>
                                <div class="d-flex flex-wrap justify-content-between" style="gap: 1%">
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="primary_option2" style="font-weight: bold">Primary Color
                                                    Option</label>
                                            </div>
                                            <label class="d-flex py-2" style="gap: 1%" for="customized_color">
                                                <div class="w-25">
                                                    <input type="color"
                                                        v-model="record_data.kiosk_colors.primary_colors[0]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 0, 'kiosk')" />
                                                </div>
                                                <div class="w-25">
                                                    <input type="color"
                                                        v-model="record_data.kiosk_colors.primary_colors[1]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 1, 'kiosk')" />
                                                </div>
                                                <div class="w-25">
                                                    <input type="color"
                                                        v-model="record_data.kiosk_colors.primary_colors[2]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 2, 'kiosk')" />
                                                </div>
                                                <div class="w-25">
                                                    <input type="color"
                                                        v-model="record_data.kiosk_colors.primary_colors[3]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 3, 'kiosk')" />
                                                </div>
                                            </label>
                                            <label class="d-flex py-2 w-100" style="gap: 1%">
                                                <div class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px">
                                                    Primary Color
                                                </div>
                                                <div class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px">
                                                    Secondary Color
                                                </div>
                                                <div class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px">
                                                    Button Color
                                                </div>
                                                <div class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px">
                                                    Button Text Color
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="secondary_option2" style="font-weight: bold">Secondary Color
                                                    Option</label>
                                            </div>

                                            <label class="d-flex py-2" style="gap: 1%" for="customized_color">
                                                <div class="w-25">
                                                    <input type="color"
                                                        v-model="record_data.kiosk_colors.secondary_colors[0]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 0, 'kiosk')" />
                                                </div>
                                                <div class="w-25">
                                                    <input type="color"
                                                        v-model="record_data.kiosk_colors.secondary_colors[1]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 1, 'kiosk')" />
                                                </div>
                                                <div class="w-25">
                                                    <input type="color"
                                                        v-model="record_data.kiosk_colors.secondary_colors[2]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 2, 'kiosk')" />
                                                </div>
                                                <div class="w-25">
                                                    <input type="color"
                                                        v-model="record_data.kiosk_colors.secondary_colors[3]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 3, 'kiosk')" />
                                                </div>
                                            </label>
                                            <label class="d-flex py-2 w-100" style="gap: 1%">
                                                <div class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px">
                                                    Primary Color
                                                </div>
                                                <div class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px">
                                                    Secondary Color
                                                </div>
                                                <div class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px">
                                                    Button Color
                                                </div>
                                                <div class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px">
                                                    Button Text Color
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label for="mobexHealthHomeColor">
                                    <h3 style="font-weight: 700">MobexHealth Home Color Scheme*</h3>
                                </label>
                                <div class="d-flex flex-wrap justify-content-between" style="gap: 1%">
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem;">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="portal_option2" style="font-weight: bold">Portal Color
                                                    Options</label>
                                            </div>
                                            <label class="flex py-2 wrap" for="customized_color">
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                                    <input type="color" name="main_background_color"
                                                        v-model="record_data.mobexhealthhome_colors.provider_color.provider_main_background_color"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 0, 'mobexHealthHomeProviderColor')" />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">Main
                                                        Background Color</div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                                    <input type="color" name="background_color"
                                                        v-model="record_data.mobexhealthhome_colors.provider_color.provider_background_color"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 1, 'mobexHealthHomeProviderColor')" />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">
                                                        Background Color</div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                                    <input type="color" name="text_color"
                                                        v-model="record_data.mobexhealthhome_colors.provider_color.provider_text_color"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 2, 'mobexHealthHomeProviderColor')" />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">Text
                                                        Color</div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                                    <input type="color" name="header_background_color"
                                                        v-model="record_data.mobexhealthhome_colors.provider_color.provider_header_background_color"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 3, 'mobexHealthHomeProviderColor')" />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">Header
                                                        Background Color</div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                                    <input type="color" name="header_text_color"
                                                        v-model="record_data.mobexhealthhome_colors.provider_color.provider_header_text_color"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 4, 'mobexHealthHomeProviderColor')" />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">Header
                                                        Text Color</div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                                    <input type="color" name="navigation_background_color"
                                                        v-model="record_data.mobexhealthhome_colors.provider_color.provider_navigation_background_color"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 5, 'mobexHealthHomeProviderColor')" />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">
                                                        Navigation Background Color</div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                                    <input type="color" name="navigation_text_color"
                                                        v-model="record_data.mobexhealthhome_colors.provider_color.provider_navigation_text_color"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 6, 'mobexHealthHomeProviderColor')" />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">
                                                        Navigation Text Color</div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="app_color_option2" style="font-weight: bold">App Color Options</label>
                                            </div>
                                            <label class="d-flex py-2" style="gap: 1%" for="customized_color">
                                                <div class="w-25">
                                                    <input type="color"
                                                        v-model="record_data.mobexhealthhome_colors.app_color.background_color"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 0, 'mobexHealthHomeAppColor')" />
                                                </div>
                                                <div class="w-25">
                                                    <input type="color"
                                                        v-model="record_data.mobexhealthhome_colors.app_color.background_text_color"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 1, 'mobexHealthHomeAppColor')" />
                                                </div>
                                                <div class="w-25">
                                                    <input type="color"
                                                        v-model="record_data.mobexhealthhome_colors.app_color.canvas_color"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 2, 'mobexHealthHomeAppColor')" />
                                                </div>
                                                <div class="w-25">
                                                    <input type="color"
                                                        v-model="record_data.mobexhealthhome_colors.app_color.canvas_text_color"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 3, 'mobexHealthHomeAppColor')" />
                                                </div>

                                            </label>
                                            <label class="d-flex py-2 w-100" style="gap: 1%">
                                                <div class="d-flex w-25 justify-content-center text-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px">
                                                    Background Color
                                                </div>
                                                <div class="d-flex w-25 justify-content-center text-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px">
                                                    Background Text Color
                                                </div>
                                                <div class="d-flex w-25 justify-content-center text-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px">
                                                    Canvas Color
                                                </div>
                                                <div class="d-flex w-25 justify-content-center text-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px">
                                                    Canvas Text Color
                                                </div>

                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label for="mhhColor">
                                    <h3 style="font-weight: 700">MHH Color Scheme*</h3>
                                </label>
                                <div class="d-flex flex-wrap justify-content-between" style="gap: 1%">
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="default" style="font-weight: bold">Default Color</label>
                                                <input type="radio" name="color_type_mhh" id="default" value="default"
                                                    v-model="mhhColorOption" style="transform: scale(0.8)"
                                                    @change="selectColorScheme($event, 'mhh')" />
                                            </div>
                                            <label class="d-flex py-2" style="gap: 1%">
                                                <div class="w-33 d-flex align-items-center px-2" style="
                                                        height: 30px;
                                                        background: #595bc4;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    ">
                                                    #595BC4
                                                </div>
                                                <div class="w-33 d-flex align-items-center px-2" style="
                                                        height: 30px;
                                                        background: #ac2734;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    ">
                                                    #AC2734
                                                </div>
                                                <div class="w-33 d-flex align-items-center px-2" style="
                                                        height: 30px;
                                                        background: #4cbc9a;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    ">
                                                    #4CBC9A
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="mhhColoroption1" style="font-weight: bold">Option 1</label>
                                                <input type="radio" name="color_type_mhh" id="option1" value="option1"
                                                    v-model="mhhColorOption" style="transform: scale(0.8)"
                                                    @change="selectColorScheme($event, 'mhh')" />
                                            </div>
                                            <label class="d-flex py-2" style="gap: 1%" for="mhhColoroption1">
                                                <div class="w-33 d-flex align-items-center px-2"
                                                    style="height: 30px; background: #000; color: #fff; font-size: 10px">
                                                    #000
                                                </div>
                                                <div class="w-33 d-flex align-items-center px-2" style="
                                                        height: 30px;
                                                        background: #3858e9;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    ">
                                                    #3858E9
                                                </div>
                                                <div class="w-33 d-flex align-items-center px-2" style="
                                                        height: 30px;
                                                        background: #15ae80;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    ">
                                                    #15AE80
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="mhhColoroption2" style="font-weight: bold">Option 2</label>
                                                <input type="radio" name="color_type_mhh" id="option2" value="option2"
                                                    v-model="mhhColorOption" style="transform: scale(0.8)"
                                                    @change="selectColorScheme($event, 'mhh')" />
                                            </div>
                                            <label class="d-flex py-2" style="gap: 1%" for="mhhColoroption2">
                                                <div class="w-33 d-flex align-items-center px-2" style="
                                                        height: 30px;
                                                        background: #2271b1;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    ">
                                                    #2271B1
                                                </div>
                                                <div class="w-33 d-flex align-items-center px-2" style="
                                                        height: 30px;
                                                        background: #9ea476;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    ">
                                                    #9EA476
                                                </div>
                                                <div class="w-33 d-flex align-items-center px-2" style="
                                                        height: 30px;
                                                        background: #dd823b;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    ">
                                                    #DD823B
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="mhh_customized_color" style="font-weight: bold">Customize Color
                                                    Option</label>
                                                <input type="radio" name="color_type_mhh" id="mhh_customized_color"
                                                    value="customized_color" v-model="mhhColorOption"
                                                    style="transform: scale(0.8)"
                                                    @change="selectColorScheme($event, 'mhh')" />
                                            </div>
                                            <label class="d-flex py-2" style="gap: 1%; flex-direction: column"
                                                for="mhh_customized_color">
                                                <div class="d-flex align-items-center py-1"
                                                    style="height: 1px; font-size: 11px">
                                                    Button Color
                                                </div>
                                                <div class="d-flex py-2" style="gap: 1%">
                                                    <div class="w-33">
                                                        <input type="color" value="#efefef" class="w-100"
                                                            v-if="mhhColorOption == 'customized_color'"
                                                            v-model="record_data.mhh_colors.colors[0][0]"
                                                            @change="selectCustomColorScheme($event, 1, 'mhh')" />
                                                        <input type="color" :value="defaultColor" class="w-100" disabled
                                                            v-else />
                                                    </div>
                                                    <div class="w-33">
                                                        <input type="color" value="#efefef" class="w-100"
                                                            v-if="mhhColorOption == 'customized_color'"
                                                            v-model="record_data.mhh_colors.colors[1][0]"
                                                            @change="selectCustomColorScheme($event, 2, 'mhh')" />
                                                        <input type="color" :value="defaultColor" class="w-100" disabled
                                                            v-else />
                                                    </div>
                                                    <div class="w-33">
                                                        <input type="color" value="#efefef" class="w-100"
                                                            v-if="mhhColorOption == 'customized_color'"
                                                            v-model="record_data.mhh_colors.colors[2][0]"
                                                            @change="selectCustomColorScheme($event, 3, 'mhh')" />
                                                        <input type="color" :value="defaultColor" class="w-100" disabled
                                                            v-else />
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center py-1"
                                                    style="height: 1px; font-size: 11px">
                                                    Button Text Color
                                                </div>
                                                <div class="d-flex py-2" style="gap: 1%">
                                                    <div class="w-33">
                                                        <input type="color" value="#efefef" class="w-100"
                                                            v-if="mhhColorOption == 'customized_color'"
                                                            v-model="record_data.mhh_colors.colors[0][1]"
                                                            @change="selectCustomColorScheme($event, 4, 'mhh')" />
                                                        <input type="color" :value="defaultColor" class="w-100" disabled
                                                            v-else />
                                                    </div>
                                                    <div class="w-33">
                                                        <input type="color" value="#efefef" class="w-100"
                                                            v-if="mhhColorOption == 'customized_color'"
                                                            v-model="record_data.mhh_colors.colors[1][1]"
                                                            @change="selectCustomColorScheme($event, 5, 'mhh')" />
                                                        <input type="color" :value="defaultColor" class="w-100" disabled
                                                            v-else />
                                                    </div>
                                                    <div class="w-33">
                                                        <input type="color" value="#efefef" class="w-100"
                                                            v-if="mhhColorOption == 'customized_color'"
                                                            v-model="record_data.mhh_colors.colors[2][1]"
                                                            @change="selectCustomColorScheme($event, 6, 'mhh')" />
                                                        <input type="color" :value="defaultColor" class="w-100" disabled
                                                            v-else />
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-4">
                                <label for="infoLink">
                                    <h3 style="font-weight: 700">Configure Project Tags</h3>
                                </label>
                                <div class="d-flex p-3 color-box gap-2"
                                    style="width: 100% !important; box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.12)">
                                    <div class="w-50 d-flex align-items-center">
                                        <div class="my-3" style="width: 38vw">
                                            <label for="tags"
                                                class="d-flex justify-content-between align-items-center upload-section">
                                                <span style="font-size: 16px; font-weight: 600; margin: 0 0 0 5%">Create
                                                    company specific Project Tag</span>
                                                <span class="upload-btn px-5 py-3 d-flex gap-2 align-items-center"
                                                    style="font-size: 14px" @click="showTagModal = true">
                                                    <img src="@/assets/px/add.png" alt="" style="height: 25px" />
                                                    Create</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="w-50">
                                        <div class="d-flex flex-wrap gap-2">
                                            <span v-for="(tag, index) in record_data.tags" :key="index"
                                                class="d-flex align-items-center gap-3 cursor-pointer py-2 px-4" style="
                                                    background: #ebeff4;
                                                    border-radius: 20px;
                                                    font-size: 15px !important;
                                                    width: fit-content;
                                                    text-transform: capitalize;
                                                ">
                                                <ejs-tooltip class="tooltipcontainer"
                                                    :content="tag.description ? tag.description : 'No Description Added'"
                                                    :target="'#tag-' + index" cssClass="customtooltip">
                                                    <span :id="'tag-' + index" style="font-size: 15px !important"
                                                        @click="openTagModal(tag, index)">
                                                        {{ tag.name.length > 15 ? tag.name.substring(0, 15) + '...' :
                                                        tag.name }}
                                                    </span>
                                                </ejs-tooltip>

                                                <ejs-tooltip class="tooltipcontainer" content="Remove"
                                                    :target="'#remove-' + index" cssClass="customtooltip">
                                                    <img :id="'remove-' + index" src="@/assets/px/cancel.svg"
                                                        alt="Remove" style="height: 20px"
                                                        @click="removetag(tag, index)" />
                                                </ejs-tooltip>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-4" v-if="!addNew">
                                <label for="infoLink">
                                    <h3 style="font-weight: 700">Configure Informational Links</h3>
                                </label>
                                <div class="d-flex p-3 color-box gap-2"
                                    style="width: 100% !important; box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.12)">
                                    <div class="w-50 d-flex align-items-center">
                                        <div class="my-3" style="width: 38vw">
                                            <label for="tags"
                                                class="d-flex justify-content-between align-items-center upload-section">
                                                <span style="font-size: 16px; font-weight: 600; margin: 0 0 0 5%">Add
                                                    Information Links</span>
                                                <span class="upload-btn px-5 py-3 d-flex gap-2 align-items-center"
                                                    style="font-size: 14px" @click="showInformationalLink = true">
                                                    <img src="@/assets/px/add.png" alt="" style="height: 25px" />
                                                    Create</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="w-50">
                                        <div class="d-flex gap-2 flex-wrap align-items-center overflow-auto"
                                            style="white-space: nowrap;">
                                            <span v-for="(info, index) in infoList" :key="index"
                                                class="d-flex align-items-center gap-3 cursor-pointer py-2 px-4"
                                                style="background: #ebeff4; border-radius: 20px; font-size: 15px !important; width: fit-content; text-transform: capitalize;">
                                                <ejs-tooltip class="tooltipcontainer" :content="info.link" target="#tag"
                                                    cssClass="customtooltip">
                                                    <span id="tag" style="font-size: 15px !important">{{ info.title
                                                    }}</span>
                                                </ejs-tooltip>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-4">
                                <label for="category">
                                    <h3 style="font-weight: 700">Configure Module Category</h3>
                                </label>
                                <div class="d-flex p-3 color-box gap-2"
                                    style="width: 100% !important; box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.12)">
                                    <div class="w-50 d-flex align-items-center">
                                        <div class="my-3" style="width: 38vw">
                                            <label for="category"
                                                class="d-flex justify-content-between align-items-center upload-section">
                                                <span style="font-size: 16px; font-weight: 600; margin: 0 0 0 5%">Create
                                                    company specific Category</span>
                                                <span class="upload-btn px-5 py-3 d-flex gap-2 align-items-center"
                                                    style="font-size: 14px" @click="showCategoryModal = true">
                                                    <img src="@/assets/px/add.png" alt="" style="height: 25px" />
                                                    Create</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="w-50">
                                        <div class="d-flex flex-wrap gap-2">
                                            <span v-for="(category, index) in categories" :key="index"
                                                @click="openCategoryModal(category, index)"
                                                class="d-flex align-items-center gap-3 cursor-pointer py-2 px-4 category-tag">
                                                <ejs-tooltip class="tooltipcontainer" :content="category.title"
                                                    target="#category" cssClass="customtooltip">
                                                    <span id="category" class="category-title">
                                                        {{ category.title.length > 15 ? category.title.substring(0, 15)
                                                        + '...' : category.title }}
                                                    </span>
                                                </ejs-tooltip>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap p-3" style="gap: 0.5em">
                                <div class="w-100">
                                    <label for="uploadLogo">
                                        <h3 style="font-weight: 700">Select or Upload Logo*</h3>
                                    </label>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="w-50">
                                        <div class="my-3" style="width: 38vw">
                                            <input type="file" name="company_logo" id="company_logo"
                                                placeholder="Upload Company logo" hidden @change="uploadImage($event)"
                                                accept="image/,.jpg, .webp, .png" :disabled="logoLoading" />
                                            <label for="company_logo"
                                                class="d-flex justify-content-between align-items-center upload-section">
                                                <span style="font-size: 14px; margin: 0 0 0 5%">Upload Company
                                                    logo</span>
                                                <span v-if="logoLoading" class="upload-btn py-3 px-3 d-flex"
                                                    style="gap: 10px">
                                                    <SpinLoader />Uploading
                                                </span>
                                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                                            </label>
                                        </div>
                                        <div class="scroll-Wrapper">
                                            <div class="scroll-container" style="gap: 1%">
                                                <div v-for="logo in logoImages" :key="logo.id"
                                                    style="position: relative; text-align: -webkit-center"
                                                    @mouseover="hoveredElementId = logo.id"
                                                    @mouseleave="hoveredElementId = null">
                                                    <img :src="logo.image"
                                                        :class="logo.file_id == companyLogo.file_id ? 'active-bg' : ''"
                                                        style="
                                                            height: 100px;
                                                            max-width: 100px;
                                                            border-radius: 20px;
                                                            cursor: pointer;
                                                        " />
                                                    <template v-if="
                                                        logo.file_id !== companyLogo.file_id &&
                                                        hoveredElementId == logo.id
                                                    ">
                                                        <div class="w-100 h-100 action-buttons">
                                                            <ejs-tooltip class="tooltipcontainer" content="Select"
                                                                target="#select" cssClass="customtooltip">
                                                                <span class="background" id="select">
                                                                    <img src="@/assets/px/select-icon.png" alt=""
                                                                        style="height: 25px"
                                                                        @click="selectCompanyLogo(logo)" />
                                                                </span>
                                                            </ejs-tooltip>
                                                            <ejs-tooltip class="tooltipcontainer" content="Delete"
                                                                target="#delete" cssClass="customtooltip">
                                                                <span class="background" id="delete">
                                                                    <img src="@/assets/px/delete-icon.png" alt=""
                                                                        style="height: 15px"
                                                                        @click="removeAsset(logo, 'company_logo')" />
                                                                </span>
                                                            </ejs-tooltip>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="logoImageErr" style="color: red">{{ logoImageErr }}</span>
                                    </div>
                                    <div class="w-50">
                                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                                        <div class="grid">
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">You can choose only one file at a
                                                    time.</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Image extensions should JPG, WEBP and
                                                    PNG.</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Resolution should be not less than
                                                    1920/1080px</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Image size should be no more than
                                                    10MB.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap p-3" style="gap: 0.5em">
                                <div class="w-100">
                                    <label for="uploadWA">
                                        <h3 style="font-weight: 700">Upload Waiting Animation*</h3>
                                    </label>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="w-50">
                                        <div class="my-3" style="width: 38vw">
                                            <input type="file" name="waiting_animation" id="waiting_animation"
                                                placeholder="Upload Waiting Animation" hidden
                                                @change="uploadImage($event)" accept=".gif"
                                                :disabled="waitingAnimationLoading" />
                                            <label for="waiting_animation"
                                                class="d-flex justify-content-between align-items-center upload-section">
                                                <span style="font-size: 14px; margin: 0 0 0 5%">Upload waiting
                                                    animation</span>
                                                <span v-if="waitingAnimationLoading" class="upload-btn py-3 px-3 d-flex"
                                                    style="gap: 10px">
                                                    <SpinLoader />Uploading
                                                </span>
                                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                                            </label>
                                        </div>
                                        <div class="scroll-Wrapper">
                                            <div class="scroll-container" style="gap: 1%">
                                                <div v-for="gif in waitingAnimations" :key="gif.id"
                                                    style="position: relative; text-align: -webkit-center"
                                                    @mouseover="hoveredElementId = gif.id"
                                                    @mouseleave="hoveredElementId = null">
                                                    <img :src="gif.image" style="
                                                            height: 100px;
                                                            max-width: 100px;
                                                            border-radius: 20px;
                                                            cursor: pointer;
                                                        " />
                                                    <template v-if="hoveredElementId == gif.id">
                                                        <div class="w-100 h-100 action-buttons">
                                                            <ejs-tooltip class="tooltipcontainer" content="Delete"
                                                                target="#delete" cssClass="customtooltip">
                                                                <span class="background" id="delete">
                                                                    <img src="@/assets/px/delete-icon.png" alt=""
                                                                        style="height: 15px"
                                                                        @click="removeAsset(gif, 'waiting_animation')" />
                                                                </span>
                                                            </ejs-tooltip>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="waitingAnimationErr" style="color: red">{{
                                            waitingAnimationErr
                                        }}</span>
                                    </div>
                                    <div class="w-50">
                                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                                        <div class="grid">
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">You can choose only one file at a
                                                    time.</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Image extensions should GIF.</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Resolution should be not less than
                                                    1920/1080px</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Image size should be no more than
                                                    10MB.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap p-3" style="gap: 0.5em">
                                <div class="w-100">
                                    <label>
                                        <h3 style="font-weight: 700">Upload Logo (For QR code use only)</h3>
                                    </label>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="w-50">
                                        <div class="my-3" style="width: 38vw">
                                            <input type="file" name="company_qr_logo" id="company_qr_logo"
                                                placeholder="Upload Company logo for QR" hidden
                                                @change="uploadImage($event)" accept="image/,.jpg, .webp, .png"
                                                :disabled="qrLogoLoading" />
                                            <label for="company_qr_logo"
                                                class="d-flex justify-content-between align-items-center upload-section">
                                                <span style="font-size: 14px; margin: 0 0 0 5%">Upload Company logo for
                                                    QR</span>
                                                <span v-if="qrLogoLoading" class="upload-btn py-3 px-3 d-flex"
                                                    style="gap: 10px">
                                                    <SpinLoader />Uploading
                                                </span>
                                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                                            </label>
                                        </div>
                                        <div class="scroll-Wrapper">
                                            <div class="scroll-container" style="gap: 1%">
                                                <div v-for="logo in qrLogoImages" :key="logo.id"
                                                    style="position: relative; text-align: -webkit-center"
                                                    @mouseover="hoveredElementId = logo.id"
                                                    @mouseleave="hoveredElementId = null">
                                                    <img :src="logo.image" style="
                                                            height: 100px;
                                                            max-width: 100px;
                                                            border-radius: 20px;
                                                            cursor: pointer;
                                                        " />
                                                    <template v-if="hoveredElementId == logo.id">
                                                        <div class="w-100 h-100 action-buttons">
                                                            <ejs-tooltip class="tooltipcontainer" content="Delete"
                                                                target="#delete" cssClass="customtooltip">
                                                                <span class="background" id="delete">
                                                                    <img src="@/assets/px/delete-icon.png" alt=""
                                                                        style="height: 15px"
                                                                        @click="removeAsset(logo, 'company_qr_logo')" />
                                                                </span>
                                                            </ejs-tooltip>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="qrLogoImageErr" style="color: red">{{ qrLogoImageErr }}</span>
                                    </div>
                                    <div class="w-50">
                                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                                        <div class="grid">
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">You can choose only one file at a
                                                    time.</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Image extensions should JPG, WEBP and
                                                    PNG.</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Resolution should be not less than
                                                    1920/1080px</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Image size should be no more than
                                                    10MB.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap p-3" style="gap: 0.5em">
                                <div class="w-100">
                                    <label for="uploadBG">
                                        <h3 style="font-weight: 700">Upload Background Images*</h3>
                                    </label>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="w-50">
                                        <div class="my-3" style="width: 38vw">
                                            <input type="file" name="company_background_image"
                                                id="company_background_image" placeholder="Upload Company background"
                                                hidden @change="uploadImage($event)" accept="image/,.jpg, .webp, .png"
                                                :disabled="backgroundLoading" />
                                            <label for="company_background_image"
                                                class="d-flex justify-content-between align-items-center upload-section">
                                                <span style="font-size: 14px; margin: 0 0 0 5%">Upload Background</span>
                                                <span v-if="backgroundLoading" class="upload-btn py-3 px-3 d-flex"
                                                    style="gap: 10px">
                                                    <SpinLoader />Uploading
                                                </span>
                                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                                            </label>
                                        </div>
                                        <div class="scroll-Wrapper">
                                            <div class="scroll-container" style="gap: 1%">
                                                <div v-for="bg in backgroundImages" :key="bg.id"
                                                    style="position: relative; text-align: -webkit-center"
                                                    @mouseover="hoveredElementId = bg.id"
                                                    @mouseleave="hoveredElementId = null">
                                                    <img :src="bg.image" style="
                                                            height: 100px;
                                                            max-width: 100px;
                                                            border-radius: 20px;
                                                            cursor: pointer;
                                                        " />
                                                    <template v-if="hoveredElementId == bg.id">
                                                        <div class="w-100 h-100 action-buttons">
                                                            <ejs-tooltip class="tooltipcontainer" content="Delete"
                                                                target="#delete" cssClass="customtooltip">
                                                                <span class="background" id="delete">
                                                                    <img src="@/assets/px/delete-icon.png" alt=""
                                                                        style="height: 15px" @click="
                                                                            removeAsset(bg, 'company_background_image')
                                                                            " />
                                                                </span>
                                                            </ejs-tooltip>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="backgroundImageErr" style="color: red">{{
                                            backgroundImageErr
                                        }}</span>
                                    </div>
                                    <div class="w-50">
                                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                                        <div class="grid">
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">You can choose only one file at a
                                                    time.</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Image extensions should JPG, WEBP and
                                                    PNG.</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Resolution should be not less than
                                                    1920/1080px</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Image size should be no more than
                                                    10MB.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap p-3" style="gap: 0.5em">
                                <div class="w-100">
                                    <label for="uploadSS">
                                        <h3 style="font-weight: 700">Upload Screen Saver Images*</h3>
                                    </label>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="w-50">
                                        <div class="my-3" style="width: 38vw">
                                            <input type="file" name="company_digital_signage_image"
                                                id="company_digital_signage_image"
                                                placeholder="Upload Company Screen Saver" hidden
                                                @change="uploadImage($event)" accept="image/,.jpg, .webp, .png"
                                                :disabled="screenSaverLoading" />
                                            <label for="company_digital_signage_image"
                                                class="d-flex justify-content-between align-items-center upload-section">
                                                <span style="font-size: 14px; margin: 0 0 0 5%">Upload Screen
                                                    Saver</span>
                                                <span v-if="screenSaverLoading" class="upload-btn py-3 px-3 d-flex"
                                                    style="gap: 10px">
                                                    <SpinLoader />Uploading
                                                </span>
                                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                                            </label>
                                        </div>
                                        <div class="scroll-Wrapper">
                                            <div class="scroll-container" style="gap: 1%">
                                                <div v-for="ds in screenSaverImages" :key="ds.id"
                                                    style="position: relative; text-align: -webkit-center"
                                                    @mouseover="hoveredElementId = ds.id"
                                                    @mouseleave="hoveredElementId = null">
                                                    <img :src="ds.image" style="
                                                            height: 100px;
                                                            max-width: 100px;
                                                            border-radius: 20px;
                                                            cursor: pointer;
                                                        " />
                                                    <template v-if="hoveredElementId == ds.id">
                                                        <div class="w-100 h-100 action-buttons">
                                                            <ejs-tooltip class="tooltipcontainer" content="Delete"
                                                                target="#delete" cssClass="customtooltip">
                                                                <span class="background" id="delete" @click="
                                                                    removeAsset(ds, 'company_digital_signage_image')
                                                                    ">
                                                                    <img src="@/assets/px/delete-icon.png" alt=""
                                                                        style="height: 15px" />
                                                                </span>
                                                            </ejs-tooltip>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="backgroundImageErr" style="color: red">{{
                                            backgroundImageErr
                                        }}</span>
                                    </div>
                                    <div class="w-50">
                                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                                        <div class="grid">
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">You can choose only one file at a
                                                    time.</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Image extensions should JPG, WEBP and
                                                    PNG.</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Resolution should be not less than
                                                    1920/1080px</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Image size should be no more than
                                                    10MB.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column p-3">
                                <div class="w-100">
                                    <label for="uploadSS">
                                        <h3 style="font-weight: 700">Registration Portal Configurations</h3>
                                    </label>
                                </div>
                                <div class="d-flex flex-column gap-4 pt-2 justify-content-between">
                                    <div class="d-flex gap-4">
                                        <Input class="w-50" inputClass="company-form-field" type="text"
                                            name="Privacy Policy Title" id="privacy_title" placeholder="Enter Title"
                                            v-model="record_data.privacy_title" label="Privacy Title"
                                            :disabled="!isEditable"
                                            :required="!!record_data.privacy_title || !!record_data.privacy_url" />

                                        <Input class="w-50" inputClass="company-form-field" type="text"
                                            name="Privacy Policy Link" id="privacy_url" rules="ValidateUrl"
                                            placeholder="Enter Link" v-model="record_data.privacy_url"
                                            label="Privacy Policy Link" :disabled="!isEditable"
                                            :required="!!record_data.privacy_title || !!record_data.privacy_url" />
                                    </div>
                                    <div class="w-100">
                                        <label for="privacy_disclaimer" class="dummy-text-class">Privacy
                                            Disclaimer</label>
                                        <textarea id="privacy_disclaimer" class="top-5" type="text"
                                            name="privacy_disclaimer" placeholder="Enter Privacy Policy Disclaimer"
                                            rows="4" cols="50" v-model="record_data.privacy_disclaimer"
                                            :disabled="!isEditable" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="isEditable" class="d-flex justify-content-end mt-5">
                            <button type="button" class="other-btn" @click="goBack" v-if="isNext">Go Back</button>
                            <button type="button" class="other-btn cancel-btn" @click="cancelEdit" v-if="!addNew">Cancel</button>
                            <button 
                                type="button" 
                                class="save-btn" 
                                @click="isNext ? (addNew ? createRecord() : updateRecord()) : onNext()">
                                {{ isNext ? (addNew ? 'Add Company Details' : 'Save Changes') : 'Next' }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
        <TagModal v-if="showTagModal" :tagList="record_data.tags" :tagInfo="tagInfo" :tagIndex="tagIndex"
            @save="saveTag" @cancel="closeTagModal" />
        <CategoryModal v-if="showCategoryModal" :categoryList="categories" :categoryInfo="categoryInfo"
            :categoryIndex="categoryIndex" @save="saveCategory" @cancel="closeCategoryModal" />
        <!-- informational-link -->
        <InformationalLinkModal v-if="showInformationalLink" :company_id="parseInt(this.company_id)"
            :infoList="infoList" @save="saveInformationalLink" @cancel="closeInformationalLinkModal" />
        <Loading v-if="loading" />
    </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import { companies, dashboardBuilder, file, upload, cardColors, informationalLinks, category } from '../../util/apiRequests';
import Loading from '@/components/general/loading/loading.vue';
import SpinLoader from '@/components/general/loading/SpinLoader.vue';
import TagModal from './TagModal.vue';
import CategoryModal from './CategoryModal.vue';
import InformationalLinkModal from './InformationalLinkModal.vue'
import CompanyLogoUpload from '../../components/company/company-information/CompanyLogoUpload.vue';

const initialFormFields = {
    company_name: '',
    company_email: '',
    company_website: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    file: '',
    phone: '',
    logo: '',
    mobexhealthhome_colors: {
        provider_color: {
            provider_main_background_color: '#FF6600',
            provider_background_color: '#ffffff',
            provider_text_color: '#000000',
            provider_header_background_color: '#329DE6',
            provider_header_text_color: '#ffffff',
            provider_navigation_background_color: '#000000',
            provider_navigation_text_color: '#ffffff',
        },
        app_color: {
            background_color: '#F1f7ff',
            background_text_color: '#000000',
            canvas_color: '#1773b1',
            canvas_text_color: '#ffffff'
        }
    },
    kiosk_colors: {
        selectedOption: 'primary_colors',
        primary_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
        secondary_colors: ['#FF6600', '#FFFFFF', '#FF6600', '#FFFFFF'],
        customized_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
        colors: ['#FFFFFF', '#000000'],
    },
    mhh_colors: {
        selectedOption: 'default',
        colors: [
            ['#595BC4', '#FFFFFF'],
            ['#AC2734', '#FFFFFF'],
            ['#4CBC9A', '#FFFFFF'],
        ],
        buttoncolor: '#595BC4',
        textcolor: '#FFFFFF',
    },
    sensitiveScreenTimeout: 30,
    sensitiveAlertTimeout: 15,
    tags: [],
    infoList: [],
    privacy_title: '',
    bufferDuration: 600,
    privacy_url: '',
    privacy_disclaimer: '',
};

const sensitiveScreenTimeoutList = [
    {
        id: 30,
        value: '30 seconds',
    },
    {
        id: 60,
        value: '1 minute',
    },
    {
        id: 120,
        value: '2 minutes',
    },
    {
        id: 180,
        value: '3 minutes',
    },
    {
        id: 240,
        value: '4 minutes',
    },
    {
        id: 300,
        value: '5 minutes',
    },
];

const sensitiveAlertTimeoutList = [
    {
        id: 15,
        value: '15 seconds',
    },
    {
        id: 30,
        value: '30 seconds',
    },
    {
        id: 60,
        value: '1 minute',
    },
];

export default {
    components: { ValidationObserver, Loading, SpinLoader, CompanyLogoUpload, TagModal, CategoryModal, InformationalLinkModal },
    name: 'CompanyDetail',
    props: {
        record_id: [String, Number],
    },
    data() {
        return {
            file: file,
            upload: upload,
            backgroundImages: [],
            backgroundImage: {},
            waitingAnimations: [],
            backgroundImageErr: null,
            waitingAnimationErr: null,
            logoImages: [],
            logoImage: {},
            qrLogoImages: [],
            qrLogoImageErr: null,
            qrLogoLoading: false,
            logoImageErr: null,
            screenSaverImages: [],
            screenSaverImage: {},
            screenSaverImageErr: null,
            isNext: false,
            companyLogo: {},
            record_data: { ...initialFormFields },
            isEditable: false,
            kioskColorOption: 'default',
            mhhColorOption: 'default',
            showUploadOverlay: false,
            loading: true,
            company_id: '',
            image: '',
            defaultColor: '#efefef',
            logoLoading: false,
            backgroundLoading: false,
            screenSaverLoading: false,
            waitingAnimationLoading: false,
            hoveredElementId: null,
            kioskColorChanged: false,
            mhhColorChanged: false,
            mobexhealthhomeAppColorChanged: false,
            mobexhealthhomeProviderColorChanged: false,
            deletedFileIds: [],
            sensitiveScreenTimeout: { ...sensitiveScreenTimeoutList[0] },
            sensitiveScreenTimeoutList,
            sensitiveAlertTimeout: { ...sensitiveAlertTimeoutList[0] },
            sensitiveAlertTimeoutList,
            showTagModal: false,
            tagInfo: null,
            tagIndex: null,
            showInformationalLink: false,
            showCategoryModal: false,
            categoryInfo: null,
            categoryIndex: null,
            categories: [],
            bufferDurationErr: '',
            addNew: false,
        };
    },
    methods: {
        async onNext() {
            window.scrollTo(0, 0);

            const form = this.$refs.form;
            const isFormValid = await form.validate();
            if (
                !isFormValid ||
                this.record_data.sensitiveScreenTimeout == '' ||
                this.record_data.sensitiveAlertTimeout == ''
            ) {
                this.$toasted.error('Looks like filled invalid data in some fields.');
                return;
            }
            if (this.record_data.sensitiveScreenTimeout <= this.record_data.sensitiveAlertTimeout) {
                this.$toasted.error('Sensitive Inactivity timeout must be less than sensitive screen timeout');
                return;
            }
            if (!Number.isInteger(this.record_data.bufferDuration) || 
                this.record_data.bufferDuration < 1 || 
                this.record_data.bufferDuration > 86400
            ) {
                this.$toasted.error('Buffer duration is not valid');
                return;
            }
            this.isNext = true;
        },
        validateBufferDuration() {
            this.bufferDurationErr = '';
            const value = this.record_data.bufferDuration;
            if (value === '') return;

            let val = Number(value);
            if (!Number.isInteger(val) || val <= 0) {
                this.bufferDurationErr = 'Value should be a valid positive Integer !';
            }
            if (val > 86400) {
                this.bufferDurationErr = 'Maximum limit is 86400 seconds!';
            }
            this.record_data.bufferDuration = val;
        },
        async createRecord() {
            try {
                this.loading = true;
                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Looks like filled invalid data in some fields.');
                    return;
                }
                if (!this.companyLogo.file_id) {
                    this.$toasted.error('Please select or upload company logo');
                    return;
                }

                if (
                    !this.logoImages.length ||
                    !this.waitingAnimations.length ||
                    !this.backgroundImages.length ||
                    !this.screenSaverImages.length
                ) {
                    this.$toasted.error(
                        'Please upload at least one image in each waiting animation/ background image/ screen saver image'
                    );
                    return;
                }

                const { mobexhealthhome_colors, ...rest } = this.record_data;

                const data = {
                    ...rest,
                    file: this.companyLogo.file_id,
                    logo: this.companyLogo.file_id.split('/')[1]?.split('.')[0],
                    kiosk_colors: JSON.stringify(rest.kiosk_colors),
                    mhh_colors: JSON.stringify(rest.mhh_colors),
                };

                const endpoint = companies.createCompany();
                const res = await this.$api.post(endpoint, data);

                if (res.data.success) {
                    const companyId = res?.data?.data?.id;

                    await this.createCardColors(companyId);
                    await this.changeActiveStatus(companyId);

                    if (this.categories.length) {
                        await this.createCategories(companyId);
                    }

                    this.resetForm();
                    this.$toasted.success('Successfully created Company');
                    await this.$router.push({ name: 'CompanyList', replace: true });
                } else {
                    throw res?.data?.error;
                }
            } catch (err) {
                this.$toasted.error(err);
            } finally {
                this.loading = false;
            }
        },
        goBack() {
            window.scrollTo(0, 0);
            this.isNext = false;
        },
        async getInformationalLink() {
            try {
                const endpoint = informationalLinks.getInformationalLinksDetails(this.company_id);
                const res = await this.$api.get(endpoint);
                if (res.status === 200) {
                    this.infoList = res.data.message || [];
                }
            } catch (error) {
                this.$toasted.error('Failed to fetch links');
            }
        },
        async init() {
            try {
                const endpoint = companies.getCompanyDetails(this.company_id);
                const res = await this.$api.get(endpoint);

                if (res.status == 200) {
                    const {
                        kiosk_colors,
                        mhh_colors,
                        logoFile,
                        sensitiveScreenTimeout,
                        sensitiveAlertTimeout,
                        tags,
                    } = res.data.info[0];
                    this.record_data = res.data.info[0];
                    this.record_data.kiosk_colors = JSON.parse(kiosk_colors);
                    this.record_data.mhh_colors = JSON.parse(mhh_colors);

                    await this.getCardColors();
                    await this.getInformationalLink();
                    await this.getCategories();

                    this.mhhColorOption = this.record_data.mhh_colors?.selectedOption ?? 'default';

                    this.record_data.tags = JSON.parse(tags) ?? [];

                    if (!mhh_colors) {
                        this.selectColorScheme({ target: { value: this.mhhColorOption } }, 'mobex-health-hub');
                    }

                    this.image = await this.geturl(logoFile);

                    this.companyLogo = {
                        id: logoFile.split('/')[1]?.split('.')[0],
                        image: this.image,
                        file_id: logoFile,
                    };
                    this.logoImages.unshift(this.companyLogo);
                    this.record_data.sensitiveScreenTimeout = sensitiveScreenTimeout;
                    this.record_data.sensitiveAlertTimeout = sensitiveAlertTimeout;
                    this.sensitiveScreenTimeout = this.sensitiveScreenTimeoutList.find(
                        (item) => item.id == sensitiveScreenTimeout
                    );
                    this.sensitiveAlertTimeout = this.sensitiveAlertTimeoutList.find(
                        (item) => item.id == sensitiveAlertTimeout
                    );


                }
            } catch (e) {
                this.$toasted.error('Failed to fetch company details');
            } finally {
                this.loading = false;
            }
        },
        async getCardColors() {
            try {
                const endpoint1 = cardColors.getCardColor(this.company_id);
                const res1 = await this.$api.get(endpoint1);

                if (res1.data.success) {
                    const {
                        provider_main_background_color,
                        provider_background_color,
                        provider_text_color,
                        provider_header_background_color,
                        provider_header_text_color,
                        provider_navigation_background_color,
                        provider_navigation_text_color,
                        background_color,
                        background_text_color,
                        canvas_color,
                        canvas_text_color
                    } = res1.data.message[0];

                    this.record_data.mobexhealthhome_colors = {
                        provider_color: null,
                        app_color: null
                    };

                    this.record_data.mobexhealthhome_colors.provider_color = {
                        provider_main_background_color,
                        provider_background_color,
                        provider_text_color,
                        provider_header_background_color,
                        provider_header_text_color,
                        provider_navigation_background_color,
                        provider_navigation_text_color
                    };

                    this.record_data.mobexhealthhome_colors.app_color = {
                        background_color,
                        background_text_color,
                        canvas_color,
                        canvas_text_color
                    };
                }
            }
            catch (error) {
                this.$toasted.error('Failed to fetch card colors');
            }
        },
        async createCardColors(companyId) {
            try {
                const mobexhealthhome_colors = {
                    ...this.record_data.mobexhealthhome_colors.provider_color,
                    ...this.record_data.mobexhealthhome_colors.app_color,
                };
                const createColorEndpoint = cardColors.createCardColor(companyId);
                await this.$api.post(createColorEndpoint, mobexhealthhome_colors);
            } catch (err) {
                this.$toasted.error(err);
            }
        },
        async createCategories(companyId) {
            try {
                const endpoint = category.updateCategories(companyId);
                await this.$api.post(endpoint, { categories: this.categories });
            } catch (err) {
                this.$toasted.error('Failed to Create Categories.');
            }
        },
        resetForm() {
            this.record_data = { ...initialFormFields };
            this.image = '';
            requestAnimationFrame(() => {
                this.$refs.form.reset();
            });
        },
        selectCompanyLogo(logo) {
            this.companyLogo = {
                id: logo.id,
                image: logo.image,
                file_id: logo.file_id,
            };
            this.image = logo.image;
        },
        async validateForm() {
            const isValid = 1; // await this.$refs.form.validate()

            if (!isValid) {
                this.$toasted.error('Please fill out all required fields.');
            }

            return isValid;
        },
        async assignId(response) {
            this.record_data.logo = response.fileId;
        },
        async back() {
            this.$router.back();
        },
        async selectFile() {
            this.$refs.upload.click();
        },
        async selectColorScheme(event, type) {
            if (type === 'kiosk') {
                switch (event.target.value) {
                    case 'option1':
                        this.record_data.kiosk_colors = {
                            selectedOption: 'option1',
                            colors: ['#5155C3', '#FFFFFF'],
                            buttoncolor: '#5155C3',
                            textcolor: '#FFFFFF',
                        };
                        break;
                    case 'option2':
                        this.record_data.kiosk_colors = {
                            selectedOption: 'option2',
                            colors: ['#4CBC9A', '#FFFFFF'],
                            buttoncolor: '#4CBC9A',
                            textcolor: '#FFFFFF',
                        };
                        break;
                    case 'customized_color':
                        this.record_data.kiosk_colors = {
                            selectedOption: 'customized_color',
                            colors: ['#efefef', '#efefef'],
                            buttoncolor: '#efefef',
                            textcolor: '#efefef',
                        };
                        break;

                    default:
                        this.record_data.kiosk_colors = {
                            selectedOption: 'default',
                            colors: ['#FFFFFF', '#5155C3'],
                            buttoncolor: '#FFFFFF',
                            textcolor: '#5155C3',
                        };
                }
            } else {
                switch (event.target.value) {
                    case 'option1':
                        this.record_data.mhh_colors = {
                            selectedOption: 'option1',
                            colors: [
                                ['#000', '#FFFFFF'],
                                ['#3858E9', '#FFFFFF'],
                                ['#15AE80', '#FFFFFF'],
                            ],
                            buttoncolor: '#000',
                            textcolor: '#FFFFFF',
                        };
                        break;
                    case 'option2':
                        this.record_data.mhh_colors = {
                            selectedOption: 'option2',
                            colors: [
                                ['#2271B1', '#FFFFFF'],
                                ['#9EA476', '#FFFFFF'],
                                ['#DD823B', '#FFFFFF'],
                            ],
                            buttoncolor: '#2271B1',
                            textcolor: '#FFFFFF',
                        };
                        break;
                    case 'customized_color':
                        this.record_data.mhh_colors = {
                            selectedOption: 'customized_color',
                            colors: [
                                ['#efefef', '#efefef'],
                                ['#efefef', '#efefef'],
                                ['#efefef', '#efefef'],
                            ],
                            buttoncolor: '#efefef',
                            textcolor: '#efefef',
                        };
                        break;

                    default:
                        this.record_data.mhh_colors = {
                            selectedOption: 'default',
                            colors: [
                                ['#595BC4', '#FFFFFF'],
                                ['#AC2734', '#FFFFFF'],
                                ['#4CBC9A', '#FFFFFF'],
                            ],
                            buttoncolor: '#595BC4',
                            textcolor: '#FFFFFF',
                        };
                }
            }
        },
        async selectCustomColorScheme(event, colorNo, type) {
            let color = event.target.value;

            if (type == 'mobexHealthHomeProviderColor') {
                const colorSequence = [
                    'provider_main_background_color',
                    'provider_background_color',
                    'provider_text_color',
                    'provider_header_background_color',
                    'provider_header_text_color',
                    'provider_navigation_background_color',
                    'provider_navigation_text_color',
                ];
                this.record_data.mobexhealthhome_colors.provider_color[colorSequence[colorNo]] = color;
                this.mobexhealthhomeProviderColorChanged = true;
            } else if (type == 'mobexHealthHomeAppColor') {
                switch (colorNo) {
                    case 0:
                        this.record_data.mobexhealthhome_colors.app_color.background_color = color;
                        break;
                    case 1:
                        this.record_data.mobexhealthhome_colors.app_color.background_text_color = color;
                        break;
                    case 2:
                        this.record_data.mobexhealthhome_colors.app_color.canvas_color = color;
                        break;
                    case 3:
                        this.record_data.mobexhealthhome_colors.app_color.canvas_text_color = color;
                        break;
                }
                this.mobexhealthhomeAppColorChanged = true;
            } else if (type == 'mhh') {
                switch (colorNo) {
                    case 1:
                        this.record_data.mhh_colors.colors[0][0] = color;
                        this.record_data.mhh_colors.buttoncolor = color;
                        break;
                    case 2:
                        this.record_data.mhh_colors.colors[1][0] = color;
                        break;
                    case 3:
                        this.record_data.mhh_colors.colors[2][0] = color;
                        break;
                    case 4:
                        this.record_data.mhh_colors.colors[0][1] = color;
                        this.record_data.mhh_colors.textcolor = color;
                        break;
                    case 5:
                        this.record_data.mhh_colors.colors[1][1] = color;
                        break;
                    case 6:
                        this.record_data.mhh_colors.colors[2][1] = color;
                        break;
                }
                this.mhhColorChanged = true;
            } else  {
                this.kioskColorChanged = true;
            }
        },
        async uploadImage(event) {
            const type = event.target.id;
            let file = event.target.files[0];
            if (file.size / 1024 / 1024 > 10) {
                if (type === 'company_logo') {
                    this.logoImageErr = 'Size should not be greater than 10MB';
                } else if (type === 'company_digital_signage_image') {
                    this.screenSaverImageErr = 'Size should not be greater than 10MB';
                } else if (type === 'waiting_animation_image') {
                    this.waitingAnimationErr = 'Size should not be greater than 10MB';
                } else if (type === 'company_qr_logo') {
                    this.qrLogoImageErr = 'Size should not be greater than 10MB';
                } else {
                    this.backgroundImageErr = 'Size should not be greater than 10MB';
                }
                return;
            } else {
                if (type === 'company_logo') {
                    this.logoImageErr = null;
                    this.logoLoading = true;
                } else if (type === 'company_digital_signage_image') {
                    this.screenSaverImageErr = null;
                    this.screenSaverLoading = true;
                } else if (type === 'waiting_animation') {
                    this.waitingAnimationErr = null;
                    this.waitingAnimationLoading = true;
                } else if (type === 'company_qr_logo') {
                    this.qrLogoImageErr = null;
                    this.qrLogoLoading = true;
                } else {
                    this.backgroundImageErr = null;
                    this.backgroundLoading = true;
                }
            }
            var reader = new FileReader();
            reader.readAsDataURL(file);
            let that = this;
            reader.onload = async function () {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('type', type);
                const endpoint = companies.uploadAsset(that.addNew ? undefined : that.company_id);
                const res = await that.$api.post(endpoint, formData);
                if (res.data.success) {
                    if (that.addNew) {
                        let response = await that.geturl(res.data.s3_link);
                        let newImage = {
                            id: res.data.id,
                            file_id: res.data.s3_link,
                            image: response,
                        };
                        if (type === 'company_logo') {
                            that.logoImages.push(newImage);
                            that.logoLoading = false;
                        } else if (type === 'company_digital_signage_image') {
                            that.screenSaverImages.push(newImage);
                            that.screenSaverLoading = false;
                        } else if (type === 'waiting_animation') {
                            that.waitingAnimations.push(newImage);
                            that.waitingAnimationLoading = false;
                        } else if (type === 'company_qr_logo') {
                            that.qrLogoImages.push(newImage);
                            that.qrLogoLoading = false;
                        } else {
                            that.backgroundImages.push(newImage);
                            that.backgroundLoading = false;
                        }
                    } else {
                        if (type === 'company_logo') {
                            that.logoLoading = false;
                        } else if (type === 'company_digital_signage_image') {
                            that.screenSaverLoading = false;
                        } else if (type === 'waiting_animation') {
                            that.waitingAnimationLoading = false;
                        } else if (type === 'company_qr_logo') {
                            that.qrLogoLoading = false;
                        } else {
                            that.backgroundLoading = false;
                        }
                        await that.getAllImages(type);
                    }
                }
            };
            reader.onerror = function (error) {
                this.logoLoading = false;
                this.backgroundLoading = false;
                this.screenSaverLoading = false;
                this.qrLogoLoading = false;
                this.waitingAnimationLoading = false;
            };
        },
        async geturl(fileId) {
            const endpoint = dashboardBuilder.getUrls();
            const requestData = {
                fileIds: [fileId],
            };
            let response = await this.$api.post(endpoint, requestData);
            return response?.data?.data[0].image;
        },
        async getAllDefaultImages() {
            try {
                const endpoint = companies.getDefaultAssetsList();
                const assetsResponse = await this.$api.get(endpoint);

                this.logoImages = assetsResponse?.data?.list.filter((item) => item.type === 'company_logo');

                this.backgroundImages = assetsResponse?.data?.list.filter(
                    (item) => item.type === 'company_background_image'
                );
                this.waitingAnimations = assetsResponse?.data?.list.filter(
                    (item) => item.type === 'waiting_animation'
                );
                this.screenSaverImages = assetsResponse?.data?.list.filter(
                    (item) => item.type === 'company_digital_signage_image'
                );
            } catch (err) {
                this.$toasted.error('Failed to fetched Default Assets');
            }
        },
        async changeActiveStatus(companyId) {
            let ids = [];

            this.logoImages.forEach((image) => {
                ids.push(image.id);
            });

            this.backgroundImages.forEach((image) => {
                ids.push(image.id);
            });

            this.screenSaverImages.forEach((image) => {
                ids.push(image.id);
            });

            this.waitingAnimations.forEach((image) => {
                ids.push(image.id);
            });

            this.qrLogoImages.forEach((image) => {
                ids.push(image.id);
            });

            const endpoint = companies.changeActiveStatus(companyId ?? this.company_id);
            const result = await this.$api.post(endpoint, { ids });

            if (!result.data.success) {
                throw result.data.error;
            }
        },
        // async renderSvg(svgXml, imgObj) {
        //     // Set primary and secondary colors
        //     let primaryColor = imgObj.kioskColor[imgObj.selectedOption][0];
        //     let secondaryColor = imgObj.kioskColor[imgObj.selectedOption][1];

        //     if (!primaryColor || !secondaryColor) {
        //         return;
        //     }

        //     // Parse SVG XML string
        //     const parser = new DOMParser();
        //     const svgDoc = parser.parseFromString(svgXml, 'image/svg+xml');

        //     // Get the SVG element
        //     const svgElement = svgDoc.documentElement;

        //     const allPaths = svgElement.querySelectorAll('path');

        //     allPaths.forEach((path) => {
        //         if (path.hasAttribute('fill')) {
        //             let color = path.getAttribute('fill').split('#')[1].toLowerCase();
        //             let colorType = path.getAttribute('id');
        //             let colorTypeAvailable = false;
        //             if (colorType === 'primaryColor') {
        //                 path.setAttribute('fill', primaryColor);
        //                 colorTypeAvailable = true;
        //             } else if (colorType === 'secondaryColor') {
        //                 path.setAttribute('fill', secondaryColor);
        //                 colorTypeAvailable = true;
        //             }
        //             if (!colorTypeAvailable) {
        //                 if (
        //                     (color[0] === 'a' ||
        //                         color[0] === 'b' ||
        //                         color[0] === 'c' ||
        //                         color[0] === 'd' ||
        //                         color[0] === 'e' ||
        //                         color[0] === 'f') &&
        //                     (color[2] === 'a' ||
        //                         color[2] === 'b' ||
        //                         color[2] === 'c' ||
        //                         color[2] === 'd' ||
        //                         color[2] === 'e' ||
        //                         color[2] === 'f') &&
        //                     (color[4] === 'a' ||
        //                         color[4] === 'b' ||
        //                         color[4] === 'c' ||
        //                         color[4] === 'd' ||
        //                         color[4] === 'e' ||
        //                         color[4] === 'f')
        //                 ) {
        //                     path.setAttribute('id', 'primaryColor');
        //                     path.setAttribute('fill', primaryColor);
        //                 } else {
        //                     path.setAttribute('id', 'secondaryColor');
        //                     path.setAttribute('fill', secondaryColor);
        //                 }
        //             }
        //         }
        //     });
        //     const svgHtml = svgElement.outerHTML;

        //     let filename = 'my-svg-file.svg';

        //     const svgData = new XMLSerializer().serializeToString(svgElement);

        //     const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });

        //     // Create a File object with the Blob and desired filename
        //     const svgFile = new File([svgBlob], filename, { type: 'image/svg+xml' });

        //     // Encode the SVG HTML string to base64
        //     let base64Svg = btoa(svgHtml);
        //     // Return the base64 encoded SVG data
        //     base64Svg = 'data:image/svg+xml;base64,' + base64Svg;

        //     const formData = new FormData();
        //     formData.append('id', imgObj.id);
        //     formData.append('file', svgFile);

        //     if (typeof imgObj?.isEmbedded === 'boolean') {
        //         formData.append('isEmbedded', imgObj?.isEmbedded);
        //     }

        //     //call the updateSvgImage API to store updated image
        //     if (imgObj.assigned_to) {
        //         const endpoint = memberProfile.updateSubModuleSvgColor();
        //         await this.$api.post(endpoint, formData);
        //     } else {
        //         const endpoint = memberProfile.updateSvgColor(imgObj.dashboard_id);
        //         await this.$api.post(endpoint, formData);
        //     }
        // },
        // async urlToSvg(imageObj) {
        //     const reader = new FileReader();
        //     let img = await fetch(imageObj.imageUrl);
        //     let imgBlob = await img.blob();
        //     reader.readAsDataURL(imgBlob);

        //     reader.onload = async () => {
        //         const base64Data = reader.result.replace(/^data:image\/svg\+xml;base64,/, '');
        //         const svgXml = atob(base64Data);
        //         await this.renderSvg(svgXml, imageObj);
        //     };
        // },
        async updateRecord() {
            try {
                this.loading = true;
                const isFormValid = await this.$refs.form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Looks like filled invalid data in some fields.');
                    return;
                }

                if (!this.companyLogo.file_id) {
                    this.$toasted.error('Please select or upload company logo');
                    return;
                }

                if (
                    !this.logoImages.length ||
                    !this.waitingAnimations.length ||
                    !this.backgroundImages.length ||
                    !this.screenSaverImages.length
                ) {
                    this.$toasted.error(
                        'Please upload atleast one image in each waiting animation/ background image/ screen saver image'
                    );
                    return;
                }

                const { id, dayt_create, dayt_mod, mobexhealthhome_colors, tags, ...rest } = this.record_data;

                const data = {
                    ...rest,
                    logo: this.companyLogo.file_id.split('/')[1]?.split('.')[0],
                    logoFile: this.companyLogo.file_id,
                    tags: tags.map(({ count, ...tag }) => tag)
                };

                const endpoint = companies.updateCompanyDetails(this.company_id);
                const res = await this.$api.put(endpoint, data);

                if (res.data.success) {
                    // It is used to update all SVG Images with new kiosk colors

                    // if (this.kioskColorChanged && res.data.data.svgList) {
                    //     let { svgImg, subModuleSvgImg } = res.data.data.svgList;
                    //     for (let i = 0; i < svgImg.length; i++) {
                    //         const file = svgImg[i].image.split('.');
                    //         const fileExtension = file[file.length - 1];
                    //         if (fileExtension == 'svg' || fileExtension == 'svg+xml') {
                    //             await this.urlToSvg(svgImg[i]);
                    //         }
                    //     }
                    //     for (let i = 0; i < subModuleSvgImg.length; i++) {
                    //         const file = subModuleSvgImg[i].image.split('.');
                    //         const fileExtension = file[file.length - 1];
                    //         if (fileExtension == 'svg' || fileExtension == 'svg+xml') {
                    //             await this.urlToSvg(subModuleSvgImg[i]);
                    //         }
                    //     }
                    // }

                    await this.changeActiveStatus();

                    if (this.mobexhealthhomeProviderColorChanged || this.mobexhealthhomeAppColorChanged) {
                        await this.updateCardColors();
                    }

                    if (this.categories.length) {
                        await this.updateCategories();
                    }

                    if (this.deletedFileIds.length) {
                        await this.deleteRemovedFileIds();
                    }

                    this.$toasted.success('Successfully Updated Company Information');

                    await this.$router.push({ name: 'CompanyList' });

                    this.isEditable = false;
                } else {
                    throw res?.data?.error;
                }
            } catch (err) {
                this.$toasted.error(err);
            } finally {
                this.loading = false;
            }
        },
        async updateCardColors() {
            try {
                const mobexhealthhome_colors = {
                    ...this.record_data.mobexhealthhome_colors?.provider_color,
                    ...this.record_data.mobexhealthhome_colors?.app_color
                };
                const endpoint = cardColors.updateCardColor(this.company_id);
                await this.$api.put(endpoint, mobexhealthhome_colors);
            } catch (error) {
                this.$toasted.error(error);
            }
        },
        async getCategories() {
            try {
                const endpoint = category.getCategories(this.company_id);
                const response = await this.$api.get(endpoint);
                this.categories = response.data ?? [];
            } catch (err) {
                this.$toasted.error('Failed to fetch Categories.');
            }
        },
        async updateCategories() {
            try {
                const endpoint = category.updateCategories(this.company_id);
                await this.$api.post(endpoint, { categories: this.categories });
            } catch (err) {
                this.$toasted.error('Failed to update Categories.');
            }
        },
        async updateCompanyId() {
            if (this.$route.params?.companyId) {
                this.company_id = this.$route.params?.companyId;
                this.isEditable = true;
            } else {
                this.company_id = this.$store.state.user.company_id;
            }
        },
        onClickEdit() {
            this.isEditable = true;
        },
        async cancelEdit() {
            await this.$router.push({ name: 'CompanyList' });
            requestAnimationFrame(() => {
                this.$refs.form.reset();
            });
            this.isEditable = false;
            this.init();
        },
        async saveInformationalLink() {
            await this.getInformationalLink();
            this.showInformationalLink = false;
        },
        closeInformationalLinkModal() {
            this.showInformationalLink = false;
        },
        async getAllImages(type = 'all') {
            try {
                const endpoint = companies.getAssetList(this.company_id);
                const assetsResponse = await this.$api.get(endpoint);

                if (assetsResponse.data.success) {
                    let data = assetsResponse?.data?.list?.filter((item) => !this.deletedFileIds.includes(item.id));

                    let company_logo_images = data?.filter((item) => item.type === 'company_logo');
                    let waiting_animation_images = data?.filter((item) => item.type === 'waiting_animation');
                    let qr_logo_images = data?.filter((item) => item.type === 'company_qr_logo');
                    let background_images = data?.filter((item) => item.type === 'company_background_image');
                    let screen_saver_images = data?.filter((item) => item.type === 'company_digital_signage_image');

                    if (type === 'company_logo') {
                        this.logoImages = company_logo_images;
                    } else if (type === 'company_background_image') {
                        this.backgroundImages = background_images;
                    } else if (type === 'company_digital_signage_image') {
                        this.screenSaverImages = screen_saver_images;
                    } else if (type === 'waiting_animation') {
                        this.waitingAnimations = waiting_animation_images;
                    } else if (type === 'company_qr_logo') {
                        this.qrLogoImages = qr_logo_images;
                    } else {
                        this.logoImages = company_logo_images;
                        this.qrLogoImages = qr_logo_images;
                        this.backgroundImages = background_images;
                        this.screenSaverImages = screen_saver_images;
                        this.waitingAnimations = waiting_animation_images;
                    }

                    if (type == 'company_logo' || type === 'all') {
                        let company_logo = this.logoImages.find(
                            (item) => item.file_id === this.companyLogo.file_id
                        );

                        this.logoImages = this.logoImages.filter(
                            (item) => item.file_id !== this.companyLogo.file_id
                        );

                        if (company_logo) {
                            this.companyLogo = {
                                id: company_logo.id,
                                image: company_logo.image,
                                file_id: company_logo.file_id,
                            };
                        }

                        this.logoImages.unshift(this.companyLogo);
                    }
                }
            } catch (err) {
                this.$toasted.error('Failed to fetched Default Assets');
            }
        },
        async removeAsset(item, type) {
            if (item.company_id && item.company_id != this.company_id) {
                this.$toasted.error("Parent company images can't be deleted");
                return;
            }
            if (type == 'company_logo') {
                if (item.file_id === this.companyLogo.file_id) {
                    this.companyLogo = {};
                }
                this.image = '';
                this.logoImages = this.logoImages.filter((element) => element.id != item.id);
            } else if (type == 'company_background_image') {
                this.backgroundImages = this.backgroundImages.filter((element) => element.id != item.id);
            } else if (type === 'waiting_animation') {
                this.waitingAnimations = this.waitingAnimations.filter((element) => element.id != item.id);
            } else if (type === 'company_qr_logo') {
                this.qrLogoImages = this.qrLogoImages.filter((element) => element.id != item.id);
            } else {
                this.screenSaverImages = this.screenSaverImages.filter((element) => element.id != item.id);
            }
            this.deletedFileIds.push(item.id);
            this.hoveredElementId = null;
            this.$toasted.success('Image deleted successfully !');
        },
        async deleteUnSaved() {
            const endpoint1 = companies.deleteUnSaved(this.company_id);
            const res = await this.$api.put(endpoint1);

            if (!res.data.success) {
                throw res.data.error;
            }
        },
        async deleteRemovedFileIds() {
            const endpoint = companies.deleteAsset(this.company_id);
            await this.$api.post(endpoint, { ids: this.deletedFileIds });
        },
        openTagModal(tag, index) {
            this.tagInfo = { ...tag };
            this.tagIndex = index;
            this.showTagModal = true;
        },
        saveTag(val) {
            if (this.tagIndex !== null) {
                this.record_data.tags[this.tagIndex] = { ...val };
            } else {
                this.record_data.tags.push(val);
            }
            this.$toasted.success(`Project Tag ${this.tagIndex != null ? 'updated' : 'added'} successfully!`);
            this.closeTagModal();
        },
        removetag(tag, index) {
            if (tag?.count) {
                return this.$toasted.error('Failed to delete tag, This tag is assigned to members');
            }
            this.record_data.tags.splice(index, 1);
        },
        closeTagModal() {
            this.showTagModal = false;
            this.tagInfo = null;
            this.tagIndex = null;
        },
        openCategoryModal(category, index) {
            this.categoryInfo = { ...category };
            this.categoryIndex = index;
            this.showCategoryModal = true;
        },
        saveCategory(val) {
            if (this.categoryIndex !== null) {
                this.categories[this.categoryIndex] = { ...val };
            } else {
                this.categories.push(val);
            }
            this.$toasted.success(`Category ${this.categoryIndex != null ? 'updated' : 'added'} successfully!`);
            this.closeCategoryModal();
        },
        closeCategoryModal() {
            this.showCategoryModal = false;
            this.categoryInfo = null;
            this.categoryIndex = null;
        },
    },
    async created() {
        if (this.$route.name === 'AddNewCompany') {
            this.isEditable = true;
            this.addNew = true;

            this.record_data = {
            ...initialFormFields,
            mobexhealthhome_colors: {
                provider_color: {
                    provider_main_background_color: '#FF6600',
                    provider_background_color: '#ffffff',
                    provider_text_color: '#000000',
                    provider_header_background_color: '#329DE6',
                    provider_header_text_color: '#ffffff',
                    provider_navigation_background_color: '#000000',
                    provider_navigation_text_color: '#ffffff',
                },
                app_color: {
                    background_color: '#F1f7ff',
                    background_text_color: '#000000',
                    canvas_color: '#1773b1',
                    canvas_text_color: '#ffffff',
                },
            },
            kiosk_colors: {
                selectedOption: 'primary_colors',
                primary_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
                secondary_colors: ['#FF6600', '#FFFFFF', '#FF6600', '#FFFFFF'],
                customized_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
                colors: ['#FFFFFF', '#000000'],
            },
            mhh_colors: {
                selectedOption: 'default',
                colors: [
                    ['#595BC4', '#FFFFFF'],
                    ['#AC2734', '#FFFFFF'],
                    ['#4CBC9A', '#FFFFFF'],
                ],
                buttoncolor: '#595BC4',
                textcolor: '#FFFFFF',
            },
            tags: [],
        };
        // This functionality is currently not in use
        // await this.getAllDefaultImages();

        this.loading = false;
        }
        else {
            await this.updateCompanyId();
            await this.deleteUnSaved();
            await this.init();
            await this.getAllImages();
        }
    },
};
</script>

<style scoped>
.image-container::before {
    content: '';
}

textarea {
    max-height: 20rem;
}
</style>
<style scoped>
.color-box {
    width: 45% !important;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.12);
}

.other-btn {
    background: #fff;
    box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
    color: #5057c3;
    min-width: 150px;
    height: 50px;
    font-family: Roboto;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid 5057C3;
    font-weight: 500;
}

.cancel-btn {
    color: #fff;
    background: var(--button-color);
}

.save-btn {
    background: #5057c3;
    box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
    color: white;
    min-width: 150px;
    height: 50px;
    font-family: Roboto;
    font-size: 16px;
    border-radius: 5px;
}

.upload-section {
    margin: 0 15% 0 0;
    border-radius: 10px;
    background: #fff;
    border: 2px solid var(--grey, #7d7d7d);
    box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
    cursor: pointer !important;
}

.scroll-Wrapper {
    width: 32vw;
    margin-bottom: 5%;
}

.upload-btn {
    border-radius: 6px;
    background: #5155c3;
    color: #fff;
    font-family: 'Roboto';
    box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
}

.scroll-container {
    overflow: auto;
    white-space: nowrap;
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
}

.active-bg {
    width: 95px !important;
    height: 95px !important;
    border: 2px solid lime;
    padding: 2px;
}

.background {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e8f0fd;
}

.action-buttons {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    top: 0;
    background: rgb(89, 90, 194, 0.85);
    border-radius: 20px;
}

.category-tag {
    background: #ebeff4;
    border-radius: 20px;
    font-size: 15px !important;
    width: fit-content;
    text-transform: capitalize;
}

.category-title {
    font-size: 15px !important;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;
}
</style>
