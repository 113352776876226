<template>
    <div class="page-body clone-dashboard">
        <NoButtonModal :open="true">
            <div class="modal-content-header bottom-20">
                <span style="font-size: 20px; font-weight: bold; margin-left: 20px">{{
                    (tagIndex !== null ? 'Edit' : 'Create') + ' Project Tag'
                }}</span>
            </div>
            <ValidationObserver ref="form">
                <form style="box-shadow: none" class="p-0" @submit.prevent="submit">
                    <div class="d-flex flex-column align-items-center" style="gap: 10px">
                        <div class="w-80 mx-4">
                            <Input
                                label="Tag Name"
                                name="Tag Name"
                                id="tagNameInput"
                                placeholder="Enter tag name"
                                inputClass="input-add-new-dashboard mt-3"
                                labelClass="class-label"
                                :input_style="'text-transform:capitalize'"
                                v-model="tagDetails.name"
                                required
                            />
                        </div>
                        <div class="w-80 mx-4 d-flex justify-content-center flex-column">
                            <label for="company" class="add-new-app-label my-2 class-label">Tag Description</label>
                            <TextArea
                                id="description"
                                class="my-2"
                                type="text"
                                name="description"
                                label=""
                                v-model="tagDetails.description"
                                :placeholder="'Enter Description message'"
                            />
                        </div>
                        <div class="my-4 d-flex justify-content-center" style="bottom: 10%">
                            <button type="submit" class="primary btn-save">Save</button>
                            <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">Cancel</button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { ValidationObserver } from 'vee-validate';

    export default {
        name: 'TagModal',
        components: { NoButtonModal, ValidationObserver },
        data() {
            return {
                tagDetails: {
                    name: '',
                    description: '',
                },
            };
        },
        props: {
            tagList: {
                type: Array,
            },
            tagInfo: {
                type: [Object, null],
            },
            tagIndex: {
                type: [Number, null],
            },
        },
        methods: {
            async submit() {
                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    return;
                }
                if (!this.tagDetails.name?.trim().length) {
                    this.tagDetails.name = '';
                    return this.$toasted.error('Please enter Tag name');
                }
                if (this.tagDetails.name.length > 50) {
                    return this.$toasted.error('Tag name should not be more than 50 characters');
                }
                const isTagNamePresent = this.tagList.find(
                    (tag, index) =>
                        tag.name.toLowerCase() === this.tagDetails.name.toLowerCase() && index !== this.tagIndex
                );
                if (isTagNamePresent) {
                    return this.$toasted.error('This Tag name is already exist');
                }
                this.$emit('save', this.tagDetails);
            },
        },
        created() {
            if (this.tagInfo) {
                this.tagDetails = { ...this.tagInfo };
            }
        },
    };
</script>

<style scoped>
    form {
        min-height: fit-content !important;
    }

    .modal-content-header {
        background-color: #f1f7ff;
        padding: 10px;
        display: flex;
        align-items: center;
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .text-link:hover {
        background: #5155c3;
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .clone-dashboard .modal__content {
        padding: 0;
        border-radius: 10px;
        min-height: fit-content;
    }

    .clone-dashboard .class-label {
        color: #666c6d;
        font-size: 1rem;
        font-weight: 600;
    }
</style>
